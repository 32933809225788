<script setup>
import AuthenticatedLayout from "@/Layouts/AuthenticatedLayout.vue"
import Tab from "@/Components/Default/Tab.vue"
import DateRangeSelector from "@/Components/Default/DateRangeSelector.vue"
import DateSelector from "@/Components/Default/DateSelector.vue"
import AddButton from "@/Components/Default/AddButton.vue"
import List from "@/Components/List/List.vue"
import Chart from "@/Components/Chart/Chart.vue"

import {onMounted, ref} from "vue";
import {HTTP} from "@/Plugins/axios";
import { useRouter } from "vue-router";

const API_URL = process.env.VUE_APP_API_URL;
const router = useRouter();

const expenses = ref();
const categories = ref();
const colours = ref();
const incomes = ref();
const tabSelected = ref('');
const endDate = ref(new Date());
const startDate = ref(new Date(new Date().setDate(endDate.value.getDate() - 7)));
const dateRangeSelected = ref('week');

const chartData = ref({
  labels: [],
  datasets: []
});

const charData = ref();
const charLabels = ref();
const charColours = ref([]);

const selectExpenses = () => {
  if (tabSelected.value !== 'expense') {
    tabSelected.value = 'expense';
    getExpenses()
    getChartData()
  }
}

const getExpenses = () => {
  HTTP({
    url: `${API_URL}expenses`,
    method: 'GET',
    data: {
      start_date: startDate.value,
      end_date: endDate.value,
    },
    headers: {
      Authorization:
          typeof localStorage !== 'undefined'
              ? localStorage.getItem('jwt')
                  ? `Bearer ${localStorage.getItem('jwt')}`
                  : ''
              : '',
    },
  })
      .then((response) => {
        expenses.value = response.data.data.expenses;
        categories.value = response.data.data.categories;
        colours.value = response.data.data.colours;
      })
      .catch((err) => {})
}

const selectIncomes = () => {
  if (tabSelected.value !== 'income') {
    tabSelected.value = 'income';
    getIncomes()
    getChartData()
  }
}

const getIncomes = () => {
  HTTP({
    url: `${API_URL}incomes`,
    method: 'GET',
    data: {
      start_date: startDate.value,
      end_date: endDate.value,
    },
  })
      .then((response) => {
        incomes.value = response.data.data.incomes;
        categories.value = response.data.data.sections;
        colours.value = response.data.data.colours;
      })
      .catch((err) => {})
}

const changeDateRange = (range) => {
  //Set date range
  let date = new Date()
  let offset = date.getTimezoneOffset()
  date = new Date(date.getTime() - (offset*60*1000))
  dateRangeSelected.value = range

  switch (range) {
    case 'day': {
      startDate.value = date.toISOString().split('T')[0]
      endDate.value = startDate.value
      break
    }
    case 'week': {
      let today = new Date(date).getDate()
      let currentDay = date.getDay()
      let startDate = new Date(new Date().setDate(today - (currentDay || 6)))
      let endDate = new Date(new Date().setDate(today - currentDay + 7))
      // console.log(startDate.toLocaleDateString())
      // console.log(endDate.toLocaleDateString())
      break
    }
    case 'month': {
      startDate.value = new Date(date.getFullYear(), date.getMonth(), 1);
      endDate.value = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      break
    }
    case 'year': {
      startDate.value = new Date(date.getFullYear(), 0, 1);
      endDate.value = new Date(date.getFullYear(), 12, 0);
      break
    }
  }
  // console.log(startDate.value)
  // console.log(endDate.value)
}

const updateDates = (startDate, endDate) => {
  // Update dates from calendar selector
  console.log(startDate)
  console.log(endDate)
}

const getChartData = () => {
  HTTP({
    url: `${API_URL}chart/${tabSelected.value}s`,
    method: 'GET',
    data: {
      start_date: startDate,
      end_date: endDate,
    },
    headers: {
      Authorization:
          typeof localStorage !== 'undefined'
              ? localStorage.getItem('jwt')
                  ? `Bearer ${localStorage.getItem('jwt')}`
                  : ''
              : '',
    },
  })
      .then((response) => {
        chartData.value['labels'] = response.data.data.labels
        let data = {
          data: response.data.data.values,
          backgroundColor: response.data.data.colours
        }
        chartData.value['datasets'] =[data]
      })
      .catch((err) => {})
}

onMounted(() => {
  // tabSelected.value = 'expenses';
  selectExpenses()
  getChartData()
})
</script>

<template>
  <AuthenticatedLayout>
    <div class="flex flex-col bg-finexpal-gray-300 w-full">
      <div class="flex justify-center gap-x-6 mt-4">
        <tab id="expenses" @click.prevent="selectExpenses" :class="[tabSelected === 'expense' ? 'bg-finexpal-gray-600' : '']">
          Expenses
        </tab>
        <tab id="incomes" @click.prevent="selectIncomes" :class="[tabSelected === 'income' ? 'bg-finexpal-gray-600' : '']">
          Incomes
        </tab>
      </div>
      <div class="flex justify-between items-center px-8 py-4">
        <DateRangeSelector :date-range-selected="dateRangeSelected" @changed-date-range-selected="changeDateRange"/>
        <DateSelector :range="dateRangeSelected" @update-dates="updateDates"/>
        <div class="w-[8rem] pl-4">
          <RouterLink v-if="tabSelected === 'expense'" to="/expenses/create">
            <AddButton />
          </RouterLink>
          <RouterLink v-else to="/incomes/create">
            <AddButton />
          </RouterLink>
        </div>
      </div>
      <div class="flex-1 w-full mb-4">
        <Chart :data="chartData" chart-type="doughnut"/>
      </div>
    </div>
    <List v-if="tabSelected === 'expense'"  :data="expenses" type="expense"/>
    <List v-else :data="incomes" type="income"/>
  </AuthenticatedLayout>
</template>