<script setup>
import AuthenticatedLayout from "@/Layouts/AuthenticatedLayout.vue";
import FloatLabel from "primevue/floatlabel";
import InputText from "primevue/inputtext";
import InputNumber from 'primevue/inputnumber';
import DatePicker from "primevue/datepicker";
import Select from 'primevue/select';
import ToggleSwitch from 'primevue/toggleswitch';
import ConfirmDialog from 'primevue/confirmdialog'
import Toast from 'primevue/toast'
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';

import {onBeforeMount, onMounted, ref} from "vue";
import {HTTP} from "@/Plugins/axios";
import { useRouter, useRoute } from "vue-router";
import ColorPicker from "primevue/colorpicker";

const API_URL = process.env.VUE_APP_API_URL;
const router = useRouter();
const route = useRoute();
const confirm = useConfirm();
const toast = useToast();

const confirmDelete = () => {
  confirm.require({
    message: 'Do you want to delete this account?',
    header: 'Delete account',
    rejectLabel: 'Cancel',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Delete',
      severity: 'danger',
    },
    accept: () => {
      deleteAccount()
    }
  });
}

const form = ref({
  name: "",
  colour: null,
});

onMounted(() => {
  // Load moneySections
  getMoneySection()
})

const deleteAccount = () => {
  HTTP({
    url: `${API_URL}sections/${route.params.id}`,
    method: 'DELETE',
    data: {
    }
  })
      .then((response) => {
        toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Account deleted', life:3000});
        router.push('/dashboard');
      })
      .catch((err) => {
        toast.add({ severity: 'error', summary: 'Rejected', detail: err, life:3000});
      })
}

const getMoneySection = () => {
  HTTP({
    url: `${API_URL}sections/${route.params.id}`,
    method: 'GET',
    data: {
    }
  })
      .then((response) => {
        let data = response.data.data
        form.value.name = data.name
        form.value.colour = data.colour
      })
      .catch((err) => {
        router.push('/accounts')
      })
}

const submit = (form) => {
  console.log(form)
  // Send api request to create income
  HTTP({
    url: `${API_URL}accounts/${route.params.id}`,
    method: 'PUT',
    data: {
      name: form.name,
      colour: form.colour,
    }
  })
      .then((response) => {
        router.push('/accounts');
      })
      .catch((err) => {})
}

const validateSubmit = (form) => {
  if (!form.name || !form.colour)
    return true;

}

const cancel = () => {
  router.push('/dashboard');
}
</script>

<template>
  <AuthenticatedLayout>
    <Toast />
    <ConfirmDialog />
    <div class="flex flex-col bg-finexpal-gray-100 w-full px-8">
      <div class="flex items-center justify-between my-4">
        <h1 class="text-3xl mt-3 mb-8">Edit account</h1>
        <button class="ml-6 rounded-md w-1/5 h-10 text-white bg-status-red-200" @click.prevent="confirmDelete">Delete</button>
      </div>
      <div class="flex flex-column h-[90%]">
        <form class="w-full">
          <FloatLabel class="w-full">
            <InputText id="name" v-model="form.name" class="w-full"/>
            <label for="name">Name</label>
          </FloatLabel>
          <div class="card flex justify-between items-center w-full pt-8">
            <!--            <FloatLabel class="w-2/5">-->
            <!--              <InputNumber v-model="form.quantity" inputId="quantity" mode="currency" currency="USD" locale="en-US" fluid class="w-full"/>-->
            <!--              <label for="quantity">Quantity</label>-->
            <!--            </FloatLabel>-->
            <!--            <div class="flex w-2/5 items-center">-->
            <!--              <label for="recurring">Set as default section</label>-->
            <!--              <ToggleSwitch inputId="recurring" v-model="form.is_recurring_income" class="ml-7"/>-->
            <!--            </div>-->
          </div>
          <div class="card flex items-center justify-between w-full pt-8">
            <!--Colour Selector-->
            <div class="card flex flex-col justify-between items-center w-full pt-8">
              <label for="colour">Select colour</label>
              <ColorPicker v-model="form.colour" inputId="colour" format="hex" class="mb-4" inline />
            </div>

          </div>
          <div class="flex w-full justify-end mt-10 mb-4">
            <button class="rounded-md w-1/5 h-10 text-white" :class="[validateSubmit(form) ? 'bg-finexpal-gray-200': 'bg-blue-700']" :disabled="validateSubmit(form)" @click.prevent="submit(form)">Create</button>
            <button class="ml-6 rounded-md w-1/5 h-10 border border-solid border-black" @click.prevent="cancel">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </AuthenticatedLayout>
</template>

<style scoped>

</style>