<script setup>
const props =defineProps({
  name: {
    type: String,
    required: true
  },
  category: {
    type: String,
    required: false
  },
  quantity: {
    type: String,
    required: true
  },
  date: {
    type: String,
    required: true
  },
  colour: {
    type: String,
    required: true,
    default: '#73C2BE'
  },
})
</script>

<template>
<div class="flex w-full h-20 bg-finexpal-gray-300 shadow-md mb-2">
<!--  Color side-->
  <div :style="`background-color:${props.colour ?? '#73C2BE'}`" class="h-full w-8">
    <div class="h-full w-2 bg-gray-600/30" />
  </div>
<!--  ItemContent-->
  <div class="flex h-full w-full">
    <div class="flex flex-col items-start justify-between py-2 pl-2 h-full w-full">
      <h1 class="text-xl font-semibold">{{ props.name }}</h1>
      <p v-if="props.category" class="text-finexpal-text-100">{{ props.category }}</p>
    </div>
    <div class="flex flex-col items-end justify-between py-2 pr-4 h-full w-36">
      <h1 class="text-xl text-bold">${{ props.quantity }}</h1>
      <p class="text-finexpal-text-100">{{ props.date }}</p>
    </div>
  </div>
</div>
</template>

<style scoped>

</style>