<script setup>
import AuthenticatedLayout from "@/Layouts/AuthenticatedLayout.vue";
import FloatLabel from "primevue/floatlabel";
import InputText from "primevue/inputtext";
import InputNumber from 'primevue/inputnumber';
import DatePicker from "primevue/datepicker";
import ConfirmDialog from 'primevue/confirmdialog'
import Toast from 'primevue/toast'
import Select from 'primevue/select'
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';

import {onMounted, ref} from "vue";
import {HTTP} from "@/Plugins/axios";
import { useRouter, useRoute } from "vue-router";

const API_URL = process.env.VUE_APP_API_URL;
const router = useRouter();
const route = useRoute();
const confirm = useConfirm();
const toast = useToast();

const confirmDelete = () => {
  confirm.require({
    message: 'Do you want to delete this account?',
    header: 'Delete account',
    rejectLabel: 'Cancel',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Delete',
      severity: 'danger',
    },
    accept: () => {
      deleteCredit()
    }
  });
}

const form = ref({
  name: "",
  limit: null,
  payday: new Date().toISOString().split('T')[0],
  cutday: new Date().toISOString().split('T')[0],
});

const days = Array.from({length: 31}, (_,k) => k+1)

onMounted(() => {
  // Load moneySections
  getCredit()
})

const deleteCredit = () => {
  HTTP({
    url: `${API_URL}credits/${route.params.id}`,
    method: 'DELETE',
    data: {
    }
  })
      .then((response) => {
        toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Account deleted', life:3000});
        router.push('/credits');
      })
      .catch((err) => {
        toast.add({ severity: 'error', summary: 'Rejected', detail: err, life:3000});
      })
}

const getCredit = () => {
  HTTP({
    url: `${API_URL}credits/${route.params.id}`,
    method: 'GET',
    data: {
    }
  })
      .then((response) => {
        let data = response.data.data
        form.value.name = data.name
        form.value.limit = data.limit
        form.value.quantity = data.quantity
        form.value.payday = data.payday
        form.value.cutday = data.cutday
      })
      .catch((err) => {
        router.push('/credits')
      })
}

const submit = (form) => {
  console.log(form)
  // Send api request to create income
  HTTP({
    url: `${API_URL}credits/${route.params.id}`,
    method: 'PUT',
    data: {
      name: form.name,
      limit: form.limit,
      quantity: form.quantity,
      payday: form.payday,
      cutday: form.cutday,
    }
  })
      .then((response) => {
        router.push('/credits');
      })
      .catch((err) => {})
}

const validateSubmit = (form) => {
  if (!form.name || !form.limit || !form.payday || !form.cutday)
    return true;

}

const cancel = () => {
  router.push('/credits');
}
</script>

<template>
  <AuthenticatedLayout>
    <Toast />
    <ConfirmDialog />
    <div class="flex flex-col bg-finexpal-gray-100 w-full px-8">
      <div class="flex items-center justify-between my-4">
        <h1 class="text-3xl mt-3 mb-8">Edit credit</h1>
        <button class="ml-6 rounded-md w-1/5 h-10 text-white bg-status-red-200" @click.prevent="confirmDelete">Delete</button>
      </div>
      <div class="flex flex-column h-[90%]">
        <form class="w-full">
          <FloatLabel class="w-full">
            <InputText id="name" v-model="form.name" class="w-full"/>
            <label for="name">Name</label>
          </FloatLabel>
          <div class="card flex justify-between items-center w-full pt-8">
            <!--Date Selector-->
            <FloatLabel class="w-2/5">
              <Select v-model="form.payday" :options="days" class="w-full"/>
              <label for="payday">Pay Day</label>
            </FloatLabel>
            <FloatLabel class="w-2/5">
              <Select v-model="form.cutday" :options="days" class="w-full"/>
              <label for="cutday">Cut Day</label>
            </FloatLabel>
          </div>
          <div class="card flex justify-between items-center w-full pt-8">
            <FloatLabel class="w-2/5">
              <InputNumber v-model="form.quantity" inputId="quantity" mode="currency" currency="USD" locale="en-US" fluid class="w-full"/>
              <label for="limit">Quantity</label>
            </FloatLabel>
            <FloatLabel class="w-2/5">
              <InputNumber v-model="form.limit" inputId="limit" mode="currency" currency="USD" locale="en-US" fluid class="w-full"/>
              <label for="limit">Limit</label>
            </FloatLabel>
          </div>
          <div class="flex w-full justify-end mt-10 mb-4">
            <button class="rounded-md w-1/5 h-10 text-white" :class="[validateSubmit(form) ? 'bg-finexpal-gray-200': 'bg-blue-700']" :disabled="validateSubmit(form)" @click.prevent="submit(form)">Save</button>
            <button class="ml-6 rounded-md w-1/5 h-10 border border-solid border-black" @click.prevent="cancel">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </AuthenticatedLayout>
</template>

<style scoped>

</style>