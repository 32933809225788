<script setup>
const props = defineProps({
  data: {
    type: Array,
    required: true
  },
  key: {
    type: Number,
    required: true
  }
})

</script>

<template>

  <RouterLink :to="`/recurrents/expenses/${props.data.id}`" class="flex flex-col align-middle bg-white w-40 h-48 rounded-xl">
    <img :src="require('../assets/images/coins.svg')" alt="Icon" class="m-auto w-2/3 h-auto font-bold">
    <p class="mb-4 text-semibold text-xl mx-auto">{{ props.data.name }}</p>
  </RouterLink>
</template>

<style scoped>

</style>