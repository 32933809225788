<script setup>
import AuthenticatedLayout from "@/Layouts/AuthenticatedLayout.vue";
import FloatLabel from "primevue/floatlabel";
import InputText from "primevue/inputtext";
import InputNumber from 'primevue/inputnumber';
import DatePicker from "primevue/datepicker";
import Select from 'primevue/select';
import ToggleSwitch from 'primevue/toggleswitch';
import ConfirmDialog from 'primevue/confirmdialog'
import Toast from 'primevue/toast'
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';

import {onBeforeMount, onMounted, ref} from "vue";
import {HTTP} from "@/Plugins/axios";
import { useRouter, useRoute } from "vue-router";

const API_URL = process.env.VUE_APP_API_URL;
const router = useRouter();
const route = useRoute();
const confirm = useConfirm();
const toast = useToast();

const confirmDelete = () => {
  confirm.require({
    message: 'Do you want to delete this recurring expense?',
    header: 'Delete recurring expense',
    rejectLabel: 'Cancel',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Delete',
      severity: 'danger',
    },
    accept: () => {
      deleteRecurringExpense()
    }
  });
}

const moneySections = ref();
const credits = ref();
const categories = ref();
const subcategories = ref();
const periods = ref([
  {name: 'Day', value: 'day'},
  {name: 'Week', value: 'week'},
  {name: 'Month', value: 'month'},
  {name: 'Year', value: 'year'},
])

const form = ref({
  name: "",
  quantity: null,
  date: new Date().toISOString().split('T')[0],
  section_id: "",
  needed: 0,
  useCredit: false,
  credit_id: null,
  every: null,
  category: null,
  subcategory: null,
});

onBeforeMount(() => {
  // getRecurringExpense()
})

onMounted(() => {
  // Load moneySections
  getMoneySections()
  // Load credits
  getCredits()
  getCategories()
  getRecurringExpense()
})

const getRecurringExpense = () => {
  HTTP({
    url: `${API_URL}recurring-expenses/${route.params.id}`,
    method: 'GET',
    data: {
    }
  })
      .then((response) => {
        let data = response.data.data
        console.log(data)
        form.value.name = data.name
        form.value.quantity = data.quantity
        form.value.start_date = data.start_date
        form.value.section_id = data.section_id ?? null
        //Todo: load correct every in select
        form.every = data.every
        if (data.needed) {
          form.value.needed = true
        }
        if (data.credit_id) {
          form.value.credit_id = data.credit_id
          form.value.useCredit = true
        }
        form.value.category = data.category_id
        if (data.subcategories) {
          form.value.subcategory = data.subcategories[0].id ?? null
        }
      })
      .catch((err) => {
        console.log(err)
        // router.push('/recurrents')
      })
}

const deleteRecurringExpense = () => {
  HTTP({
    url: `${API_URL}recurring-expenses/${route.params.id}`,
    method: 'DELETE',
    data: {
    }
  })
      .then((response) => {
        toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Expense deleted', life:3000});
        router.push('/recurrents');
      })
      .catch((err) => {
        toast.add({ severity: 'error', summary: 'Rejected', detail: err, life:3000});
      })
}

const getMoneySections = () => {
  HTTP({
    url: `${API_URL}get/sections`,
    method: 'GET',
    data: {
    }
  })
      .then((response) => {
        moneySections.value = response.data.data;
        form.value.section_id = response.data.data[0]['id']
      })
      .catch((err) => {
        console.log(err)
      })
}

const getCredits = () => {
  HTTP({
    url: `${API_URL}credits`,
    method: 'GET',
    data: {
    }
  })
      .then((response) => {
        if (response.data.data && response.data.data.length > 0)
          credits.value = response.data.data;
      })
      .catch((err) => {
        console.log(err)
      })
}

const getCategories = () => {
  HTTP({
    url: `${API_URL}categories`,
    method: 'GET',
    data: {
    }
  })
      .then((response) => {
        categories.value = response.data.data;
      })
      .catch((err) => {
        console.log(err)
      })
}

const submit = (form) => {
  console.log(form)
  // Send api request to create expense
  HTTP({
    url: `${API_URL}recurring-expenses/${route.params.id}`,
    method: 'PUT',
    data: {
      name: form.name,
      quantity: form.quantity,
      start_date: form.start_date,
      section_id: form.section_id,
      needed: form.needed,
      useCredit: form.useCredit,
      credit_id: form.credit_id,
      is_recurring_expense: form.is_recurring_expense,
      every: form.every,
      category: form.category,
      subcategory: form.subcategory,
    }
  })
      .then((response) => {
        console.log(response)
        router.push('/recurrents');
      })
      .catch((err) => {})
}

const validateSubmit = (form) => {
  if (!form.name || !form.quantity || !form.category || !form.section_id || !form.every)
    return true;

  if (form.useCredit && !form.credit_id)
    return true;
}

const cancel = () => {
  router.push('/recurrents');
}
</script>

<template>
  <AuthenticatedLayout>
    <Toast />
    <ConfirmDialog />
    <div class="flex flex-col bg-finexpal-gray-100 w-full px-8">
      <div class="flex items-center justify-between my-4">
        <h1 class="text-3xl mt-3 mb-8">Edit recurring expense</h1>
        <button class="ml-6 rounded-md w-1/5 h-10 text-white bg-status-red-200" @click.prevent="confirmDelete">Delete</button>
      </div>
      <div class="flex flex-column h-[90%]">
        <form class="w-full">
          <FloatLabel class="w-full">
            <InputText id="name" v-model="form.name" class="w-full"/>
            <label for="name">Name</label>
          </FloatLabel>
          <div class="card flex justify-between items-center w-full pt-8">
            <FloatLabel class="w-2/5">
              <!--              <InputText id="quantity" v-model="form.quantity" class="w-full"/>-->
              <InputNumber v-model="form.quantity" inputId="quantity" mode="currency" currency="USD" locale="en-US" fluid class="w-full"/>
              <label for="quantity">Quantity</label>
            </FloatLabel>
            <!--Date Selector-->
            <FloatLabel class="w-2/5">
              <DatePicker v-model="form.start_date" inputId="start_date" showIcon fluid :showOnFocus="false" dateFormat="yy-mm-dd" class="w-full"/>
              <label for="start_date">Date (Y-m-d)</label>
            </FloatLabel>
          </div>
          <div class="card flex justify-between items-center w-full pt-8">
            <FloatLabel class="w-2/5">
              <Select inputId="category" v-model="form.category" :options="categories" filter optionLabel="name" optionValue="id" placeholder="Select a category" class="w-full" />
              <label for="category">Category</label>
            </FloatLabel>
            <FloatLabel class="w-2/5">
              <Select inputId="subcategory" v-model="form.subcategory" :options="subcategories" filter optionLabel="name" optionValue="id" placeholder="Select a subcategory" class="w-full"/>
              <label for="subcategory">Subcategory</label>
            </FloatLabel>

          </div>
          <div class="card flex justify-between items-center w-full pt-8">
            <FloatLabel class="w-2/5">
              <Select v-model="form.section_id" :options="moneySections" optionLabel="name" optionValue="id" placeholder="Select a money section" class="w-full" :disabled="form.useCredit" />
              <label for="quantity">Money Section</label>
            </FloatLabel>

          </div>
          <div class="card flex items-center w-2/5 pt-8">
            <div class="flex items-center">
              <label for="needed">Needed</label>
              <ToggleSwitch inputId="needed" v-model="form.needed" class="ml-4" :chec/>
            </div>
          </div>
          <div v-if="credits" class="flex items-center justify-between w-full pt-8">
            <div class="flex w-2/5 items-center">
              <label for="useCredit">Credit</label>
              <ToggleSwitch inputId="useCredit" v-model="form.useCredit" class="ml-7"/>
            </div>
            <div v-if="form.useCredit" class="mt-8 flex w-2/5 items-center">
              <FloatLabel class="w-full">
                <Select inputId="credit" v-model="form.credit_id" :options="credits" optionLabel="name" optionValue="id" placeholder="Select a credit" class="w-full" />
                <label for="credit">Credit</label>
              </FloatLabel>
            </div>
          </div>
          <div class="card flex items-center justify-between w-full pt-8">
<!--            <div class="flex w-3/5 sm:w-2/5 items-center">-->
<!--              <label for="recurring" class="text-start w-auto">Is recurrent expense</label>-->
<!--              <ToggleSwitch inputId="recurring" v-model="form.is_recurring_expense" class="ml-7"/>-->
<!--            </div>-->
            <div class="flex w-2/5 items-center">
              <FloatLabel class="w-full">
                <Select inputId="every" v-model="form.every" :options="periods" optionLabel="name" optionValue="value" placeholder="Select the period" class="w-full" />
                <label for="every">Period</label>
              </FloatLabel>
            </div>
          </div>
          <div class="flex w-full justify-end mt-10 mb-4">
            <button class="rounded-md w-1/5 h-10 text-white" :class="[validateSubmit(form) ? 'bg-finexpal-gray-200': 'bg-blue-700']" :disabled="validateSubmit(form)" @click.prevent="submit(form)">Save</button>
            <button class="ml-6 rounded-md w-1/5 h-10 border border-solid border-black" @click.prevent="cancel">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </AuthenticatedLayout>
</template>

<style scoped>

</style>