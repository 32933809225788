import axios from "axios";
import {Consts} from "@/Plugins/Consts";

export const HTTP  =
    axios.create({
        url: process.env.VUE_APP_API_URL,
        // method,
        // data: body,
        headers: {
            Authorization:
                typeof localStorage !== 'undefined'
                    ? localStorage.getItem('jwt')
                        ? `Bearer ${localStorage.getItem('jwt')}`
                        : ''
                    : '',
        },
    });


    //
    // .then((response) => response.data.data)
    // .catch((err) => {
    //     if (
    //         showError ||
    //         err.response.data.error_code === Consts.ErrorCode.INVALID_TOKEN
    //     ) {
    //         let errorMessage =
    //             'An unexpected error has occurred, please try again later';
    //
    //         if (err.response.data.error_code === Consts.ErrorCode.INVALID_TOKEN) {
    //             errorMessage = 'Your session has expired, please login again';
    //             localStorage.removeItem('jwt');
    //         } else if (err.response.data && err.response.data.error_code) {
    //             const error = err.response.data;
    //             if (errorList && errorList[error.error_code]) {
    //                 errorMessage = errorList[error.error_code];
    //             } else {
    //                 switch (error.error_code) {
    //                     case 43:
    //                         errorMessage =
    //                             'You do not have permission to perform this request';
    //                         break;
    //                     case 20:
    //                         errorMessage = 'The requested resource could not be found';
    //                         break;
    //                     default:
    //                         break;
    //                 }
    //             }
    //         }
    //         console.error('Error messages', errorMessage);
    //
    //         return { ...err.response.data, message: errorMessage };
    //     }
    //     return err.response.data;
    // });
