<script setup>
import AuthenticatedLayout from "@/Layouts/AuthenticatedLayout.vue";
import FloatLabel from "primevue/floatlabel";
import InputText from "primevue/inputtext";
import InputNumber from 'primevue/inputnumber';
import DatePicker from "primevue/datepicker";

import { ref } from "vue";
import {HTTP} from "@/Plugins/axios";
import { useRouter } from "vue-router";

const API_URL = process.env.VUE_APP_API_URL;
const router = useRouter();


const form = ref({
  name: "",
  limit: null,
  payday: null,
  cutday: null,
});

const days = Array.from({length: 31}, (_,k) => k+1)

const submit = (form) => {
  // Send api request to create income
  form.payday = new Date(form.payday).getDate()
  form.cutday = new Date(form.cutday).getDate()
  console.log(form)
  HTTP({
    url: `${API_URL}credits`,
    method: 'POST',
    data: {
      name: form.name,
      limit: form.limit,
      payday: form.payday,
      cutday: form.cutday,
    }
  })
      .then((response) => {
        router.push('/credits');
      })
      .catch((err) => {})
}

const validateSubmit = (form) => {
  if (!form.name || !form.limit || !form.payday || !form.cutday)
    return true;

}

const cancel = () => {
  router.push('/credits');
}
</script>

<template>
  <AuthenticatedLayout>
    <div class="bg-finexpal-gray-100 w-full px-8">
      <h1 class="text-3xl mt-3 mb-8">Create Credit</h1>
      <div class="flex flex-column h-[90%]">
        <form class="w-full">
          <FloatLabel class="w-full">
            <InputText id="name" v-model="form.name" class="w-full"/>
            <label for="name">Name</label>
          </FloatLabel>
          <div class="card flex justify-between items-center w-full pt-8">
            <!--Date Selector-->
            <FloatLabel class="w-2/5">
              <Select v-model="form.payday" :options="days" class="w-full"/>
              <label for="payday">Pay Day</label>
            </FloatLabel>
            <FloatLabel class="w-2/5">
              <Select v-model="form.cutday" :options="days" class="w-full"/>
              <label for="cutday">Cut Day</label>
            </FloatLabel>
          </div>
          <div class="card flex justify-between items-center w-full pt-8">
            <FloatLabel class="w-2/5">
              <InputNumber v-model="form.limit" inputId="limit" mode="currency" currency="USD" locale="en-US" fluid class="w-full"/>
              <label for="limit">Limit</label>
            </FloatLabel>
          </div>
          <div class="flex w-full justify-end mt-10 mb-4">
            <button class="rounded-md w-1/5 h-10 text-white" :class="[validateSubmit(form) ? 'bg-finexpal-gray-200': 'bg-blue-700']" :disabled="validateSubmit(form)" @click.prevent="submit(form)">Create</button>
            <button class="ml-6 rounded-md w-1/5 h-10 border border-solid border-black" @click.prevent="cancel">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </AuthenticatedLayout>
</template>

<style scoped>

</style>