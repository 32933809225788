<script setup>
import AuthenticatedLayout from "@/Layouts/AuthenticatedLayout.vue"
import RecurrentExpense from "@/Components/RecurrentExpense.vue";
import RecurrentIncome from "@/Components/RecurrentIncome.vue";

import {onBeforeMount, ref} from "vue";
import {HTTP} from "@/Plugins/axios";
import { useRouter } from "vue-router";
import AddButton from "@/Components/Default/AddButton.vue";

const API_URL = process.env.VUE_APP_API_URL;
const router = useRouter();

const recurringExpenses = ref();
const recurringIncomes = ref();

const getRecurringExpenses = () => {
  HTTP({
    url: `${API_URL}recurring-expenses`,
    method: 'GET',
  })
      .then((response) => {
        recurringExpenses.value = response.data.data;
        console.log(response.data.data)
      })
      .catch((err) => {})
}


const getRecurringIncomes = () => {
  HTTP({
    url: `${API_URL}recurring-incomes`,
    method: 'GET',
  })
      .then((response) => {
        recurringIncomes.value = response.data.data;
        console.log(recurringIncomes.value)
      })
      .catch((err) => {})
}

onBeforeMount(() => {
  getRecurringExpenses()
  getRecurringIncomes()
})
</script>

<template>
  <AuthenticatedLayout>
    <div class="flex flex-col max-h-screen w-full sm:bg-finexpal-gray-100">
      <div class="flex justify-center my-6 max-h-full">
        <h1 class="text-bold text-3xl">Recurrents</h1>
<!--        <SearchBar class="hidden sm:flex mt-0"></SearchBar>-->
      </div>
      <div class="flex h-[85%] mt-4">
        <div class="flex flex-col w-1/2 h-full">
          <div class="flex justify-center align-middle">
            <p class="text-2xl text-semibold">Expenses</p>
            <RouterLink to="/recurrents/expenses/create">
              <AddButton class="ml-10"/>
            </RouterLink>
          </div>
          <div class="grid grid-cols-1 sm:grid-cols-2 justify-items-center gap-y-8 h-full overflow-y-auto w-full no-scrollbar">
            <!--Listado de expense recurrents-->
            <RecurrentExpense v-for="item in recurringExpenses" :data="item" :key="item.id" class="mt-6 my-2"/>
          </div>
        </div>
        <div class="border-r h-full border-black border-solid"></div>
        <div class="flex flex-col w-1/2 h-full">
          <div class="flex justify-center align-middle">
            <p class="text-2xl text-semibold">Incomes</p>
            <RouterLink to="/recurrents/incomes/create">
              <AddButton class="ml-10"/>
            </RouterLink>
          </div>
          <div class="grid grid-cols-1 sm:grid-cols-2 justify-items-center gap-y-8 h-full overflow-y-auto w-full no-scrollbar">
            <!--Listado de expense recurrents-->
            <RecurrentIncome v-for="item in recurringIncomes" :data="item" :key="item.id" class="mt-6"/>
          </div>
        </div>
      </div>
    </div>
  </AuthenticatedLayout>
</template>