<script setup>
import AuthenticatedLayout from "@/Layouts/AuthenticatedLayout.vue";
import AddButton from "@/Components/Default/AddButton.vue";
import Tab from "@/Components/Default/Tab.vue";
import Credit from "@/Components/Credit/Credit.vue";


import {onBeforeMount, ref} from "vue";
import {HTTP} from "@/Plugins/axios";
import { useRouter } from "vue-router";

const API_URL = process.env.VUE_APP_API_URL;
const router = useRouter();

const credits = ref();
// Todo: implementar listado con los creditos de la petición
const getCredits = () => {
  HTTP({
    url: `${API_URL}credits`,
    method: 'GET',
    // data: {
    //   start_date: startDate.value,
    //   end_date: endDate.value,
    // },
  })
      .then((response) => {
        credits.value = response.data.credits;
        console.log(response.data.credits);
      })
      .catch((err) => {
        console.log(err)
      })
}

onBeforeMount(() => {
  getCredits()
})
</script>

<template>
  <AuthenticatedLayout>
    <div class="flex flex-col bg-finexpal-gray-300 w-full">
      <div class="flex justify-center gap-x-6 mt-4">
        <tab id="expenses" class="bg-finexpal-gray-600">
          Credits
        </tab>
      </div>
      <div class="flex justify-center align-center p-4">
        <div class="flex flex-col justify-center align-center ml-52">
          <div class="flex justify-center align-center underline underline-offset-4 font-semibold">
            <span>$ 4,300</span>
          </div>
          <div class="flex justify-center align-center">
            <span>/  $ 8,000</span>
          </div>
        </div>
        <div class="w-[8rem] pl-4 ml-16 mr-4">
          <RouterLink to="/credits/create">
            <AddButton />
          </RouterLink>
        </div>
      </div>

      <div v-for="item in credits" class="flex-1 w-full mb-4">
        <Credit
            :id="item.id"
            :name="item.name"
            :quantity="item.quantity"
            :limit="item.limit"
            :payday="item.payday"
            :cutday="item.cutday"
            class="ml-10 mt-10" />
      </div>
    </div>
  </AuthenticatedLayout>
</template>