<script setup>
import AuthenticatedLayout from "@/Layouts/AuthenticatedLayout.vue"
import Tab from "@/Components/Default/Tab.vue"
import DateRangeSelector from "@/Components/Default/DateRangeSelector.vue"
import DateSelector from "@/Components/Default/DateSelector.vue"
import AddButton from "@/Components/Default/AddButton.vue"
import List from "@/Components/List/List.vue"
import Chart from "@/Components/Chart/Chart.vue"

import {onMounted, ref} from "vue";
import {HTTP} from "@/Plugins/axios";
import { useRouter } from "vue-router";

const API_URL = process.env.VUE_APP_API_URL;
const router = useRouter();

const sections = ref();
const colours = ref();
const total = ref(0);

const chartData = ref({
  labels: [],
  datasets: []
});

const getSections = () => {
  HTTP({
    url: `${API_URL}sections`,
    method: 'GET',
    // data: {
    //   start_date: startDate.value,
    //   end_date: endDate.value,
    // },
  })
      .then((response) => {
        sections.value = response.data.data.sections;
        colours.value = response.data.data.colours;
        total.value = response.data.data.total;
      })
      .catch((err) => {})
}


const getChartData = () => {
  HTTP({
    url: `${API_URL}chart/sections`,
    method: 'GET',
    headers: {
      Authorization:
          typeof localStorage !== 'undefined'
              ? localStorage.getItem('jwt')
                  ? `Bearer ${localStorage.getItem('jwt')}`
                  : ''
              : '',
    },
  })
      .then((response) => {
        chartData.value['labels'] = response.data.data.labels
        let data = {
          label: 'Sections',
          data: response.data.data.values,
          backgroundColor: response.data.data.colours
        }
        chartData.value['datasets'] =[data]
      })
      .catch((err) => {})
}

onMounted(() => {
  getSections()
  getChartData()
})
</script>

<template>
  <AuthenticatedLayout>
    <div class="flex flex-col bg-finexpal-gray-300 w-full">
      <div class="flex justify-center gap-x-6 mt-4">
        <tab id="expenses" class="bg-finexpal-gray-600">
          Accounts
        </tab>
      </div>
      <div class="flex justify-center align-center p-4">
        <div class="flex underline underline-offset-4 font-semibold">
          <span>$ {{total}}</span>
        </div>
      </div>
      <div class="w-[8rem] pl-4 ml-auto mr-4">
        <RouterLink to="/accounts/create">
          <AddButton />
        </RouterLink>
      </div>
      <div class="flex-1 w-full mt-20 mb-4">
        <Chart :data="chartData" chart-type="bar"/>
      </div>
    </div>
    <List :data="sections" type="section"/>
  </AuthenticatedLayout>
</template>