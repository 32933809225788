<script setup>
import GuestLayout from '@/Layouts/GuestLayout.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { useToast } from 'primevue/usetoast';
import {HTTP} from "@/Plugins/axios";
import { useRouter, useRoute } from 'vue-router'
import {ref, computed} from 'vue';

const API_URL = process.env.VUE_APP_API_URL;
const toast = useToast();
const router = useRouter()

defineProps({
    canResetPassword: {
        type: Boolean,
    },
    status: {
        type: String,
    },
});

const form = ref({
  signup: 'email',
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
  remember: false,
  error: "",
});

const signup = ({signup, name, email, password}) => {

  if (!form.value.email || !form.value.password || !form.value.confirmPassword) {
    toast.add({ severity: 'error', summary: 'Debes de llenar todos los campos', detail: '', life: 3000 });
    return;
  }

  if (form.value.password !== form.value.confirmPassword) {
    toast.add({ severity: 'error', summary: 'Contraseñas no coindiden', detail: '', life: 3000 });
    return;
  }

  HTTP({
    url: `${API_URL}signup`,
    method: 'POST',
    data: {
      signup,
      name,
      email,
      password
    }
  })
    .then((response) => {
      localStorage.setItem('jwt', response.data.access_token);
      router.push('/dashboard');
    })
    .catch((err) => {
      form.error = err.message;
      toast.add({ severity: 'error', summary: 'Error', detail: err.message, life: 3000 });
    })

};
</script>

<template>
    <GuestLayout>
      <Toast />
      <Head title="Log in" />

        <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
            {{ status }}
        </div>

        <form @submit.prevent="signup(form)">
            <div>
                <InputLabel for="name" value="Name" />

                <TextInput
                    id="name"
                    type="text"
                    class="mt-1 block w-full"
                    v-model="form.name"
                    required
                    autofocus
                />

<!--                <InputError v-if="form.error.length !== 0" class="mt-2" :message="form.error" />-->
            </div>

          <div>
            <InputLabel for="email" value="Email" />

            <TextInput
                id="email"
                type="email"
                class="mt-1 block w-full"
                v-model="form.email"
                required
                autofocus
            />

            <!--                <InputError v-if="form.error.length !== 0" class="mt-2" :message="form.error" />-->
          </div>

            <div class="mt-4">
                <InputLabel for="password" value="Password" />

                <TextInput
                    id="password"
                    type="password"
                    class="mt-1 block w-full"
                    v-model="form.password"
                    required
                    autocomplete="current-password"
                />

<!--                <InputError v-if="form.error.length !== 0" class="mt-2" :message="form.error" />-->
            </div>

          <div class="mt-4">
            <InputLabel for="confirmPassword" value="Confirm password" />

            <TextInput
                id="confirmPassword"
                type="password"
                class="mt-1 block w-full"
                v-model="form.confirmPassword"
                required
                autocomplete="current-password"
            />

            <!--                <InputError v-if="form.error.length !== 0" class="mt-2" :message="form.error" />-->
          </div>

          <div class="block mt-4">
            <label class="flex items-center">
              <span class="ml-2 text-sm text-gray-600">Ya tienes una cuenta? </span>
              <span class="ml-2 text-blue-600 underline"> <RouterLink to="/login"> Inicia sesión</RouterLink></span>
            </label>
          </div>

            <div class="flex items-center justify-end mt-4">
                <Link
                    v-if="canResetPassword"
                    :href="route('password.request')"
                    class="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Forgot your password?
                </Link>

<!--                <PrimaryButton class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">-->
                <PrimaryButton class="ml-4">
                    Sign Up
                </PrimaryButton>
            </div>
        </form>
    </GuestLayout>
</template>
