<script setup>

</script>

<template>
  <Button class="flex justify-center w-8 h-8 mt-2 mr-2">
    <img :src="require('../../assets/images/edit.svg')" alt="Icon" class="w-12 h-auto font-bold">
  </Button>
</template>

<style scoped>

</style>