export const Consts = {
    'ErrorCode': {
        'INVALID_TOKEN': 401,
    }
}

export const SidebarTree = {
    sidebarTree: [
        {
            label: 'Dashboard',
            icon:'chart.svg',
            href: '/dashboard',
            children:[],
        },
        {
            label: 'Accounts',
            icon:'money.svg',
            href: '/accounts',
            children:[]
        },
        {
            label: 'Credit',
            icon:'credit.svg',
            href: '/credits',
            children:[]
        },
        {
            label: 'Charts',
            icon:'barchart.svg',
            href: '/charts',
            children:[]
        },
        {
            label: 'Categories',
            icon:'categories.svg',
            href: '/categories',
            children:[]
        },
        {
            label: 'Recurrents',
            icon:'clock.svg',
            href: '/recurrents',
            children:[]
        },
        {
            label: 'Configuration',
            icon:'gear.svg',
            href: '/configuration',
            children:[]
        },
    ],
}