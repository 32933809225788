<script setup>
import { ref, watch } from "vue";
import Select from "primevue/select";

const props = defineProps({
  dateRangeSelected: {
    type: String,
    required: true
  }
})

const periods = ref([
  { name: 'Day', value: 'day' },
  { name: 'Week', value: 'week' },
  { name: 'Month', value: 'month' },
  { name: 'Year', value: 'year' },
]);

const selected = ref(props.dateRangeSelected);

watch(() => props.dateRangeSelected, (newValue, oldValue) => {
  selected.value = newValue;
});
</script>

<template>
  <Select
      v-model="selected"
      :options="periods"
      optionLabel="name"
      optionValue="value"
      :option="selected"
      :change="$emit('changedDateRangeSelected', selected)"
      class="!bg-finexpal-gray-200 !rounded-xl w-[8rem] border-none shadow-md"
  />
</template>