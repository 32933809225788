<script setup>
// import AuthenticatedLayout from "@/Layouts/AuthenticatedLayout.vue";
import {SidebarTree} from "@/Plugins/Consts";
import Sidebar from "@/Components/Sidebar/Sidebar.vue"
import {onBeforeMount, onMounted} from "vue";
import {HTTP} from "@/Plugins/axios";

onBeforeMount(() => {
  // loadUserInfo()
})

const API_URL = process.env.VUE_APP_API_URL;
</script>

<template>
  <div class="flex flex-row bg-gray-200 min-h-screen">
    <Head title="Dashboard" />
    <Sidebar :sidebarTree="SidebarTree.sidebarTree" class="max-sm:hidden"/>
    <div id="content" class="flex flex-col sm:flex-row bg-finexpal-gray-200 w-full">
      <slot />
    </div>
  </div>
</template>