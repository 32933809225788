import { createApp } from 'vue';
import App from './App.vue';
import './assets/tailwind.css';
import router from './Router';

import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import ToastService from 'primevue/toastservice';
import Toast from "primevue/toast";
import ConfirmationService from "primevue/confirmationservice";

const theme = {

};

const app = createApp(App)
    .use(PrimeVue, {
        theme: {
            preset: Aura,
            options: {
                darkModeSelector: '@media (prefers-color-scheme: light)'
            }
        }
        // unstyled: true,
        // pt: theme
    })
    .use(ToastService)
    .use(ConfirmationService)
    .use(router)
    .component('Toast', Toast)
    .mount('#app');
