<script setup>
import AuthenticatedLayout from "@/Layouts/AuthenticatedLayout.vue";
import FloatLabel from "primevue/floatlabel";
import InputText from "primevue/inputtext";
import InputNumber from 'primevue/inputnumber';
import DatePicker from "primevue/datepicker";
import Select from 'primevue/select';
import ToggleSwitch from 'primevue/toggleswitch';
import ConfirmDialog from 'primevue/confirmdialog'
import Toast from 'primevue/toast'
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';

import {onBeforeMount, onMounted, ref} from "vue";
import {HTTP} from "@/Plugins/axios";
import { useRouter, useRoute } from "vue-router";

const API_URL = process.env.VUE_APP_API_URL;
const router = useRouter();
const route = useRoute();
const confirm = useConfirm();
const toast = useToast();

const confirmDelete = () => {
  confirm.require({
    message: 'Do you want to delete this recurring income?',
    header: 'Delete income',
    rejectLabel: 'Cancel',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Delete',
      severity: 'danger',
    },
    accept: () => {
      deleteRecurringIncome()
    }
  });
}

const moneySections = ref();
const credits = ref();
const categories = ref();
const subcategories = ref();
const periods = ref([
  {name: 'Day', value: 'day'},
  {name: 'Week', value: 'week'},
  {name: 'Month', value: 'month'},
  {name: 'Year', value: 'year'},
])

const form = ref({
  name: "",
  quantity: null,
  start_date: new Date().toISOString().split('T')[0],
  section_id: "",
  is_recurring_income: false,
  every: null,
});

onBeforeMount(() => {
  getMoneySections()
})

onMounted(() => {
  getRecurringIncome()
})

const getRecurringIncome = () => {
  HTTP({
    url: `${API_URL}recurring-incomes/${route.params.id}`,
    method: 'GET',
    data: {
    }
  })
      .then((response) => {
        let data = response.data.data
        form.value.name = data.name
        form.value.quantity = data.quantity
        form.value.start_date = data.start_date
        form.value.section_id = data.section_id
      })
      .catch((err) => {
        // router.push('/dashboard')
      })
}

const deleteRecurringIncome = () => {
  HTTP({
    url: `${API_URL}recurring-incomes/${route.params.id}`,
    method: 'DELETE',
    data: {
    }
  })
      .then((response) => {
        toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Income deleted', life:3000});
        router.push('/recurrents');
      })
      .catch((err) => {
        toast.add({ severity: 'error', summary: 'Rejected', detail: err, life:3000});
      })
}

const getMoneySections = () => {
  HTTP({
    url: `${API_URL}get/sections`,
    method: 'GET',
    data: {
    }
  })
      .then((response) => {
        moneySections.value = response.data.data;
        form.value.section_id = response.data.data[0]['id']
      })
      .catch((err) => {
        console.log(err)
      })
}

const submit = (form) => {
  console.log(form)
  // Send api request to create income
  HTTP({
    url: `${API_URL}recurring-incomes/${route.params.id}`,
    method: 'PUT',
    data: {
      name: form.name,
      quantity: form.quantity,
      start_date: form.start_date,
      section_id: form.section_id,
      is_recurring_income: form.is_recurring_income,
      every: form.every,
    }
  })
      .then((response) => {
        router.push('/recurrents');
      })
      .catch((err) => {})
}

const validateSubmit = (form) => {
  if (!form.name || !form.quantity || !form.start_date || !form.section_id || !form.every)
    return true;

  if (form.is_recurring_income && !form.every)
    return true;
}

const cancel = () => {
  router.push('/recurrents');
}
</script>

<template>
  <AuthenticatedLayout>
    <Toast />
    <ConfirmDialog />
    <div class="flex flex-col bg-finexpal-gray-100 w-full px-8">
      <div class="flex items-center justify-between my-4">
        <h1 class="text-3xl mt-3 mb-8">Edit recurring income</h1>
        <button class="ml-6 rounded-md w-1/5 h-10 text-white bg-status-red-200" @click.prevent="confirmDelete">Delete</button>
      </div>
      <div class="flex flex-column h-[90%]">
        <form class="w-full">
          <FloatLabel class="w-full">
            <InputText id="name" v-model="form.name" class="w-full"/>
            <label for="name">Name</label>
          </FloatLabel>
          <div class="card flex justify-between items-center w-full pt-8">
            <FloatLabel class="w-2/5">
              <InputNumber v-model="form.quantity" inputId="quantity" mode="currency" currency="USD" locale="en-US" fluid class="w-full"/>
              <label for="quantity">Quantity</label>
            </FloatLabel>
            <!--Date Selector-->
            <FloatLabel class="w-2/5">
              <DatePicker v-model="form.start_date" inputId="start_date" showIcon fluid :showOnFocus="false" dateFormat="yy-mm-dd" class="w-full"/>
              <label for="start_date">Start Date (Y-m-d)</label>
            </FloatLabel>
          </div>
          <div class="card flex justify-between items-center w-full pt-8">
            <FloatLabel class="w-2/5">
              <Select v-model="form.section_id" :options="moneySections" optionLabel="name" optionValue="id" placeholder="Select a money section" class="w-full" :disabled="form.useCredit" />
              <label for="quantity">Money Section</label>
            </FloatLabel>
          </div>
          <div class="card flex items-center justify-between w-full pt-8">
            <div class="flex w-2/5 items-center">
              <FloatLabel class="w-full">
                <Select inputId="every" v-model="form.every" :options="periods" optionLabel="name" optionValue="value" placeholder="Select the period" class="w-full" />
                <label for="every">Period</label>
              </FloatLabel>
            </div>
          </div>
          <div class="flex w-full justify-end mt-10 mb-4">
            <button class="rounded-md w-1/5 h-10 text-white" :class="[validateSubmit(form) ? 'bg-finexpal-gray-200': 'bg-blue-700']" :disabled="validateSubmit(form)" @click.prevent="submit(form)">Save</button>
            <button class="ml-6 rounded-md w-1/5 h-10 border border-solid border-black" @click.prevent="cancel">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </AuthenticatedLayout>
</template>

<style scoped>

</style>