<script setup>
import Checkbox from '@/Components/Checkbox.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { useToast } from 'primevue/usetoast';
import {HTTP} from "@/Plugins/axios";
import { useRouter, useRoute } from 'vue-router'
import { ref } from "vue";


const API_URL = process.env.VUE_APP_API_URL;
const toast = useToast();
const router = useRouter()

defineProps({
    canResetPassword: {
        type: Boolean,
    },
    status: {
        type: String,
    },
});

const form = ref({
  login: 'email',
  email: '',
  password: '',
  remember: false,
  error: "",
});

const login = ({login, email, password}) => {
  HTTP({
    url: `${API_URL}login`,
    method: 'POST',
    data: {
      login,
      email,
      password
    }
  })
    .then((response) => {
      localStorage.setItem('jwt', response.data.access_token);
      router.push('/dashboard');
    })
    .catch((err) => {
      form.value.error = err.message;
      toast.add({ severity: 'error', summary: 'Credenciales incorrectas', detail: '', life: 3000 });
    })

};
</script>

<template>
    <GuestLayout>
      <Toast />
      <Head title="Log in" />

        <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
            {{ status }}
        </div>

        <form @submit.prevent="login(form)">
            <div>
                <InputLabel for="email" value="Email" />

                <TextInput
                    id="email"
                    type="email"
                    class="mt-1 block w-full"
                    v-model="form.email"
                    required
                    autofocus
                    autocomplete="username"
                />

<!--                <InputError v-if="form.error.length !== 0" class="mt-2" :message="form.error" />-->
            </div>

            <div class="mt-4">
                <InputLabel for="password" value="Password" />

                <TextInput
                    id="password"
                    type="password"
                    class="mt-1 block w-full"
                    v-model="form.password"
                    required
                    autocomplete="current-password"
                />

<!--                <InputError v-if="form.error.length !== 0" class="mt-2" :message="form.error" />-->
            </div>
          <div class="block mt-4">
            <label class="flex items-center">
              <span class="ml-2 text-sm text-gray-600">No tienes una cuenta aún? </span>
              <span class="ml-2 text-blue-600 underline"> <RouterLink to="/signup"> Registrate aquí</RouterLink></span>
            </label>
          </div>

<!--            <div class="block mt-4">-->
<!--                <label class="flex items-center">-->
<!--                    <Checkbox name="remember" v-model:checked="form.remember" />-->
<!--                    <span class="ml-2 text-sm text-gray-600">Remember me</span>-->
<!--                </label>-->
<!--            </div>-->

            <div class="flex items-center justify-end mt-4">
                <Link
                    v-if="canResetPassword"
                    :href="route('password.request')"
                    class="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Forgot your password?
                </Link>

<!--                <PrimaryButton class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">-->
                <PrimaryButton class="ml-4">
                    Log in
                </PrimaryButton>
            </div>
        </form>
    </GuestLayout>
</template>
